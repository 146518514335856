import { ClientRouter, replace } from "@with-cardinal/client-router";
import { fonts } from "@with-cardinal/fonts";
import { Fx } from "@with-cardinal/fx";
import { BetterForm, RouterLink } from "@with-cardinal/router-fx";
import { ClipboardInput } from "./cx/clipboard-input.js";
import { ColorSelect } from "./cx/color-select.js";
import { DateInput } from "./cx/date-input.js";
import { GardenHideZone } from "./cx/garden-hide-zone.js";
import { ReferenceForm } from "./cx/reference-form.js";
import { ShopPageHero } from "./cx/shop-page-hero.js";
import { TextInput } from "./cx/text-input.js";
import { ImageUpload } from "./fx/image-upload.js";
import { ImageZoom } from "./fx/image-zoom.js";
import { JournalFormImages } from "./fx/journal-form-images.js";
import { MarketingHeader } from "./fx/marketing-header.js";
import { MobileMenu } from "./fx/mobile-menu.js";
import { OverflowMenu } from "./fx/overflow-menu.js";
import { PlausibleEvent } from "./fx/plausible-event.js";
import { RadioCard } from "./fx/radio-card.js";
import { SelectInput } from "./fx/select-input.js";
import { ShowPlantPage } from "./fx/show-plant-page.js";
import { TagExperiment } from "./fx/tag-experiment.js";
import { TextareaInput } from "./fx/textarea-input.js";

import "./style/index.css.js";
import * as Sentry from "@sentry/browser";
import { GardenSearch } from "./cx/garden-search.js";
import { TextComplete } from "./cx/text-complete.js";

if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://c1913298c9a0d10b8499bf8c0b347117@o1148936.ingest.sentry.io/4506310221627392",
		release: `leafy-client@${process.env.RELEASE}`,
	});
}

// set up plausible client
window.plausible =
	window.plausible ||
	((...args) => {
		// biome-ignore lint/suspicious/noAssignInExpressions: Code snippet from plausible
		// biome-ignore lint/style/noArguments: Code snippet from plausible
		(window.plausible.q = window.plausible.q || []).push(args);
	});

fonts("HolenVintage", "Chiswick");

ClientRouter.onChange = replace;
ClientRouter.start();

customElements.define("text-input", TextInput);
customElements.define("date-input", DateInput);
customElements.define("shop-page-hero", ShopPageHero);
customElements.define("color-select", ColorSelect);
customElements.define("garden-search", GardenSearch);
customElements.define("garden-hide-zone", GardenHideZone);
customElements.define("text-complete", TextComplete);
customElements.define("clipboard-input", ClipboardInput);
customElements.define("reference-form", ReferenceForm);

Fx.register("router-link", RouterLink);
Fx.register("better-form", BetterForm);
Fx.register("select-input", SelectInput);
Fx.register("textarea-input", TextareaInput);
Fx.register("image-upload", ImageUpload);
Fx.register("radio-card", RadioCard);
Fx.register("overflow-menu", OverflowMenu);
Fx.register("show-plant-page", ShowPlantPage);
Fx.register("marketing-header", MarketingHeader);
Fx.register("mobile-menu", MobileMenu);
Fx.register("journal-form-images", JournalFormImages);
Fx.register("image-zoom", ImageZoom);
Fx.register("tag-experiment", TagExperiment);
Fx.register("plausible-event", PlausibleEvent);
